import React from "react";
import styled from "styled-components";
import { spacers, breakpoints, colors } from "../styles/__variables";
import Paragraph from "./Paragraph";

interface WarumListItemProps {
  item: string;
  index: number;
}

const ListenItem = styled.li`
  padding: ${`${spacers.mobile}px`};
  background: ${colors.lightGreen.background};
  margin-bottom: ${`${spacers.mobile}px`};

  &:last-of-type {
    padding-bottom: ${`${spacers.desktop}px`};
    border-bottom-right-radius: 100px;
  }
`;

const WarumListenItem: React.FC<WarumListItemProps> = ({ item, index }) => {
  return (
    <ListenItem key={index}>
      <Paragraph color={colors.lightGreen.text}>{item}</Paragraph>
    </ListenItem>
  );
};

export default WarumListenItem;
