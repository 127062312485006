import React from "react";
import Helmet from "react-helmet";
import TwoThirdsLayout from "../layouts/TwoThirdsLayout";
import WarumListe from "../components/WarumList";
import Testimonials from "../components/Testimonials";
import Title from "../components/Title";

import Wrapper from "../layouts/Wrapper";

import { colors } from "../styles/__variables";

const styles = {
  backgroundLeft: colors.white,
  backgroundRight: colors.lightBrown.background,
  navColor: colors.lightBrown.base,
  navColorDesktop: colors.lightBrown.base,
};

// no innerflex, align innerflex items to center!

const Warum: React.FC = () => {
  return (
    <TwoThirdsLayout styles={styles}>
      <Helmet
        title="Warum – Rundum HR"
        link={[
          {
            rel: "shortcut icon",
            type: "image/png",
            href: `./assets/favicon/android-chrome-192x192.png`,
          },
        ]}
      />

      <div>
        <Wrapper>
          <Title color={colors.lightBrown.base}>Warum RundumHR</Title>
          <WarumListe />
        </Wrapper>
      </div>
      <div>
        <div>
          <Testimonials />
        </div>
      </div>
    </TwoThirdsLayout>
  );
};

export default Warum;
