import React from "react";
import styled from "styled-components";
import WarumListenItem from "./WarumListenItem";
import { warumItems } from "./data";

const Liste = styled.ul`
  margin: 0;
  list-style-type: none;
`;

const WarumListe: React.FC = () => {
  return (
    <Liste>
      {warumItems.map((warum) => (
        <WarumListenItem index={warum.index} item={warum.item} />
      ))}
    </Liste>
  );
};

export default WarumListe;
