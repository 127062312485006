import React from "react";
import styled from "styled-components";
import { testimonialItems } from "./data";
import Paragraph from "./Paragraph";
import { colors } from "../styles/__variables";
import Spacer from "../layouts/StandardSpacer";

// interface

interface TestimonialProps {
  index: number;
}

const Item = styled.div`
  padding: 5px;
`;

const TestimonialItem: React.FC<TestimonialProps> = ({ index }) => {
  return (
    <Item>
      <Paragraph color={colors.lightBrown.text}>
        «{testimonialItems[index].item}»
      </Paragraph>
      <Spacer />
      <Paragraph color={colors.lightBrown.text}>
        <b>{testimonialItems[index].author}</b>
        <br />
        <em>{testimonialItems[index].job}</em>
      </Paragraph>
    </Item>
  );
};

export default TestimonialItem;
