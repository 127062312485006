import React, { useState } from "react";
import styled from "styled-components";
import { testimonialItems } from "./data";
import TestimonialItem from "./TestimonialItem";
import { spacers, breakpoints } from "../styles/__variables";
import Spacer from "../layouts/StandardSpacer";

const Testimonials: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const maxIndex: number = testimonialItems.length - 1;

  // refactoring?
  const nextTestimonial = (): void => {
    if (currentIndex + 1 > maxIndex) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevTestimonial = (): void => {
    if (currentIndex - 1 < 0) {
      setCurrentIndex(maxIndex);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const TestimonialDiv = styled.div`
    padding: ${`${spacers.mobile}px`};
    display: flex;
    flex: 1;
    overflow-y: scroll;
    min-height: 50vh;
    flex-direction: column;
    justify-content: center;

    @media screen AND (min-width: ${`${breakpoints.md}px`}) {
      padding: ${`${spacers.tablet}px`};
    }

    @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
      height: 100vh;
      padding: ${`${spacers.desktop}px`} ${`${spacers.desktop}px`}
        ${`${spacers.desktop}px`} ${`${spacers.tablet}px`};
    }
  `;

  const Arrow = styled.div`
    width: 40px;
    height: 20px;
    background-size: 40px 20px;
    margin: 0 auto;
    cursor: pointer;
    &.up {
      background-image: url("/assets/gestaltungselemente/arrow-up.svg");
    }

    &.down {
      background-image: url("/assets/gestaltungselemente/arrow-down.svg");
    }
  `;

  return (
    <TestimonialDiv>
      <Arrow className="up" onClick={prevTestimonial} />
      <Spacer />
      <TestimonialItem index={currentIndex} />
      <Spacer />
      <Arrow className="down" onClick={nextTestimonial} />
    </TestimonialDiv>
  );
};

export default Testimonials;
